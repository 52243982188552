import { post } from '@/utils/request';

// 列表
const getListQwsq = (params) => post({
  url: '/store/QiyeApp.EwechatAuthConfigCtl/list',
  data: {
    ...params,
  },
});
//获取企业微信授权
const getRedirectAuthURL = (params) => post({
  url: '/store/QiyeApp.EwechatApp/getRedirectAuthURL',
  data: {
    ...params,
  },
});
//编辑授权信息
const getEdit = (params) => post({
  url: '/store/QiyeApp.EwechatAuthConfigCtl/edit',
  data: {
    ...params,
  },
});
//解除应用授权绑定
const getUnlock = (params) => post({
  url: '/store/QiyeApp.EwechatAuthConfigCtl/unlock',
  data: {
    ...params,
  },
});

//同步用户信息
const synchronCustomer = (params) => post({
  url: '/store/QiyeApp.EwechatAuthConfigCtl/synchronCustomer',
  data: {
    ...params,
  },
});





export {
  getListQwsq,
  getRedirectAuthURL,
  getEdit,
  synchronCustomer,
  getUnlock
};
