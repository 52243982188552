<template>
  <div>
    <div class="content">
      <div class="card">
        <el-button type="primary" style="width" @click="handleAdd"
          >新增绑定</el-button
        >
      </div>
      <div class="table">
        <el-table :data="list" stripe height="720">
          <el-table-column
            type="index"
            label="序号"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            label="会员体系名称"
            align="center"
            prop="title"
          ></el-table-column>
          <el-table-column
            label="绑定公众号"
            align="center"
            prop="wechat_name"
          ></el-table-column>
          <el-table-column
            label="绑定门店"
            align="center"
            prop="hierarchy_name"
          ></el-table-column>
          <el-table-column
            label="操作人"
            align="center"
            prop="store_user_name"
          ></el-table-column>
          <el-table-column
            label="最后修改时间"
            align="center"
            prop="update_time"
          ></el-table-column>
          <el-table-column label="操作" align="center" prop="action">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)"
                >详情</el-button
              >
              <el-button type="text" @click="handleEditBind(scope.row)"
                >编辑</el-button
              >
              <el-button type="text" @click="handleDelBind(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      title="绑定详情"
      :visible.sync="detailVisiable"
      :close-on-click-modal="false"
      :before-close="cancelDetailDialog"
      width="25%"
    >
      <div style="line-height: 32px; font-size: 14px">
        <div style="display: flex">
          <div style="width: 120px; color: #909399">会员体系名称</div>
          <div>{{ info.title }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 120px; color: #909399">公众号名称</div>
          <div>{{ info.wechat_name }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 120px; color: #909399">绑定门店</div>
          <div>{{ info.hierarchy_name }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 120px; color: #909399">操作人</div>
          <div>{{ info.store_user_name }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 120px; color: #909399">公众号名称</div>
          <div>{{ info.wechat_name }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 120px; color: #909399">创建时间</div>
          <div>{{ info.create_time }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 120px; color: #909399">最后修改时间</div>
          <div>{{ info.update_time }}</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="visiable"
      :title="title"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :before-close="cancelBindDialog"
      width="40%"
    >
      <div>
        <el-form :model="info" :rules="rules" ref="info">
          <div style="display: flex">
            <div>
              <div class="title">
                <div class="span"></div>
                选择会员体系
              </div>
              <el-form-item
                label="选择会员体系"
                :label-width="formLabelWidth"
                prop="client_system_id"
              >
                <el-select
                  v-model="info.client_system_id"
                  placeholder="请选择会员体系"
                >
                  <template v-for="item in systemList">
                    <el-option
                      :label="item.title"
                      :value="item.client_system_id"
                      :key="item.client_system_id"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <div class="title">
                <div class="span"></div>
                填写公众号信息
              </div>
              <div style="font-size: 12px; margin: -20px 0 20px 25px">
                说明：若无申请公众号，可不填写，直接用会员体系绑定门店
              </div>
              <el-form-item label="公众号名称" :label-width="formLabelWidth">
                <el-input
                  placeholder="请输入公众号名称"
                  v-model="info.wechat_name"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="AppID(公众号)" :label-width="formLabelWidth">
                <el-input
                  placeholder="请输入AppID(公众号)"
                  v-model="info.appid"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="App Secret" :label-width="formLabelWidth">
                <el-input
                  placeholder="请输入App Secret"
                  v-model="info.appsecret"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="商户号" :label-width="formLabelWidth">
                <el-input
                  placeholder="请输入商户号"
                  v-model="info.mch_id"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="支付秘钥" :label-width="formLabelWidth">
                <el-input
                  placeholder="请输入支付秘钥"
                  v-model="info.key"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="证书公钥" :label-width="formLabelWidth">
                <el-input
                  placeholder="请输入证书公钥"
                  v-model="info.apiclient_cert"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="证书私钥" :label-width="formLabelWidth">
                <el-input
                  placeholder="请输入证书私钥"
                  v-model="info.apiclient_key"
                  style="width: 100%"
                />
              </el-form-item>
              <el-form-item
                label="域名配置"
                :label-width="formLabelWidth"
                prop="jump_url"
              >
                <el-input
                  placeholder="请输入域名配置"
                  v-model="info.jump_url"
                  style="width: 100%"
                />
              </el-form-item>
            </div>
            <div
              style="
                width: 1px;
                height: 600px;
                background: #f5f6fa;
                margin: 0 20px 0 40px;
              "
            ></div>
            <div>
              <div class="title">
                <div class="span"></div>
                选择绑定企业
              </div>
              <el-form-item
              >
                <el-select
                  v-model="info.ewechat_authinfo_id"
                  placeholder="请选择绑定企业"
                >
                  <template v-for="item in enterpriseList">
                    <el-option
                      :label="item.corp_name"
                      :value="item.ewechat_authinfo_id"
                      :key="item.ewechat_authinfo_id"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
              <div class="title">
                <div class="span"></div>
                选择绑定门店
              </div>

              <template v-for="(item, index) in hierarchyList">
                <div
                  :key="index"
                  style="margin-bottom: 20px; margin-left: 30px"
                >
                  <el-checkbox
                    v-model="item.client_system_binding_id"
                    :disabled="
                      (item.bind_id && !client_system_binding_id) ||
                      (item.bind_id &&
                        item.bind_id !== client_system_binding_id &&
                        client_system_binding_id)
                        ? true
                        : false
                    "
                    >{{ item.name }}</el-checkbox
                  >
                </div>
              </template>
            </div>
          </div>
          <div style="text-align: center">
            <el-button type="primary" plain @click="cancelBindDialog"
              >取消</el-button
            >
            <el-button type="primary" @click="handleCommit">保存</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  systemBindingList,
  getClientSystemList,
  getClientSystemHierarchyList,
  systemBindingCreate,
  systemBindingUpdate,
  getClientSystemInfo,
  systemBindingDelete,
} from "@/api/member/system/Index.js";
import {getListQwsq} from "@/api/system/authorization/enterprise.js";
export default {
  data() {
    return {
      list: [],
      total: 0,
      visiable: false,
      detailVisiable: false,
      title: "新增会员体系绑定",
      info: {},
      formLabelWidth: "120px",
      systemList: [],
      hierarchyList: [],
      enterpriseList:[],
      client_system_binding_id: null,
      rules: {
        client_system_id: [
          {
            required: true,
            message: "请选择会员体系",
            trigger: "change",
          },
        ],
        jump_url: [
          {
            required: true,
            message: "请填写域名配置",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getBindingList();
    this.getClientSystemHierarchyList();
    this.getClientSystemList();
    this.getListQwsq();
  },
  methods: {
    //企微已授权客户列表
    getListQwsq(){
        getListQwsq().then((res) => {
          if(res.code ===1){

            this.enterpriseList=res.data;
            console.log(res)
          }
        })
    },
    cancelDetailDialog() {
      this.detailVisiable = false;
      this.info = {};
    },
    cancelBindDialog() {
      this.client_system_binding_id = null;
      this.title = "新增会员体系绑定";
      this.visiable = false;
      this.info = {};
      this.hierarchyList.forEach((item) => {
        item.client_system_binding_id = false;
      });
    },
    getClientSystemHierarchyList() {
      getClientSystemHierarchyList().then((res) => {
        if (res.code === 1) {
          res.data.map((item) => {
            item.bind_id = item.client_system_binding_id;
            return item;
          });
          this.hierarchyList = res.data;
        }
      });
    },
    /*
     *  会员体系下拉数据
     */
    getClientSystemList() {
      getClientSystemList().then((res) => {
        if (res.code === 1) {
          this.systemList = res.data;
        }
      });
    },
    /*
     *  会员绑定列表
     */
    getBindingList() {
      systemBindingList().then((res) => {
        if (res.code === 1) {
          this.list = res.data.list;
        }
      });
    },
    handleAdd() {
      this.visiable = true;
      this.getClientSystemHierarchyList();
      this.getClientSystemList();
    },
    /*
     *  新增&编辑会员体系绑定
     */
    handleCommit() {
      this.$refs.info.validate((valid) => {
        if (valid) {
          let binding_hierarchy_id = [];
          this.hierarchyList.forEach((item) => {
            if (this.title === "新增会员体系绑定") {
              if (item.client_system_binding_id && !item.bind_id) {
                binding_hierarchy_id.push(item.hierarchy_id);
              }
            } else {
              console.log(item.client_system_binding_id);
              if (item.client_system_binding_id === true) {
                binding_hierarchy_id.push(item.hierarchy_id);
              }
            }
          });
          const data = {
            ...this.info,
            binding_hierarchy_id: binding_hierarchy_id,
          };
          console.log(data);
          if (this.title === "新增会员体系绑定") {
            systemBindingCreate(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "创建成功",
                });
                this.cancelBindDialog();
                this.getBindingList();
              }
            });
          } else {
            delete data.hierarchy_name;
            delete data.update_time;

            systemBindingUpdate(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "更新成功",
                });
                this.cancelBindDialog();
                this.getBindingList();
              }
            });
          }
        }
      });
    },
    handleDetail(item) {
      this.detailVisiable = true;
      this.info = item;
    },
    /*
     *  删除
     */
    handleDelBind(item) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        systemBindingDelete({
          client_system_binding_id: item.client_system_binding_id,
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getBindingList();
          }
        });
      });
    },
    /*
     *  编辑
     */
    handleEditBind(item) {
      this.client_system_binding_id = item.client_system_binding_id;
      item.binding_hierarchy_id.split(",").forEach((item) => {
        this.hierarchyList = this.hierarchyList.map((i) => {
          if (Number(item) === i.hierarchy_id) {
            i.client_system_binding_id = true;
          }
          return i;
        });
      });
      getClientSystemInfo({
        client_system_binding_id: item.client_system_binding_id,
      }).then((res) => {
        if (res.code === 1) {
          this.info = { ...res.data };
        }
      });

      this.visiable = true;
      this.title = "编辑会员体系绑定";
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 20px;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  font-size: 16px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
</style>
